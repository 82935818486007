import { UserContext } from 'context/UserContext';
import { lazy, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import Loadable from 'ui-component/Loadable';
// material-ui
import { AppBar, Box, CssBaseline, Toolbar } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
// project imports
import { IconBroadcastOff } from '@tabler/icons-react';
import useOnlineStatus from 'hooks/isOnline';
import { SET_MENU } from 'store/actions';
import { drawerWidth } from 'store/constant';
import ErrorScreen from 'ui-component/ErrorScreen';

const Header = Loadable(lazy(() => import('./Header')));
const Sidebar = Loadable(lazy(() => import('./Sidebar')));

// assets

// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' && prop !== 'theme' })(({ theme, open }) => ({
    ...theme.typography.mainContent,
    background: theme.palette.grey[50],
    margin: 0,
    marginTop: '3.5rem',
    // width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginRight: 0,
    overflowX: 'hidden',
    // minHeight: 'calc(100vh - 88px)',
    // background: theme.palette.grey[100],
    // width: `calc(100% - ${drawerWidth}px)`, // height: `calc(100% - 72px)`,
    padding: '0',
    ...(!open && {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        width: `100%`,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        [theme.breakpoints.up('md')]: {
            marginLeft: '0',
            marginRight: 0
            // maxWidth: `1366px`
        },
        [theme.breakpoints.up('lg')]: {
            marginLeft: '0',
            marginRight: 0
            // maxWidth: `1366px`
            // width: `100%`
        },
        [theme.breakpoints.down('md')]: {
            marginLeft: '0',
            marginRight: 0
            // maxWidth: `1366px`,
            // padding: '16px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: 0 // maxWidth: '100vw',
            // width: `100%`,
            // padding: '16px',
        },
        [theme.breakpoints.down('xs')]: {
            marginLeft: 0, // maxWidth: '100vw',
            // width: `100%`,
            // padding: '16px',
            marginRight: 0
        }
    }),
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        }),
        marginLeft: drawerWidth,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        width: `calc(100% - ${drawerWidth}px)`,
        [theme.breakpoints.down('md')]: {
            marginLeft: drawerWidth,
            width: `calc(100% - ${drawerWidth}px)`
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: 0,
            width: `100%`
        }
    })
}));

// ==============================|| MAIN LAYOUT ||============================== //

const PublicLayout = () => {
    const theme = useTheme();
    const [userContext, setUserContext] = useContext(UserContext);

    const leftDrawerOpened = useSelector((state) => state.customization.opened);
    const dispatch = useDispatch();
    // const { data, error, loading } = useQuery(GET_ROUTE, { variables: { id: adminid } });
    const handleLeftDrawerToggle = () => {
        dispatch({ type: SET_MENU, opened: !leftDrawerOpened });
    };
    // dispatch({ type: SET_MENU, opened: false });
    // if (navigator.windowControlsOverlay.visible) {
    //     console.log('window controls overlay visible');
    //     setMiniNavbar(true);
    // } else {
    //     console.log('window controls overlay not visible');
    //     setMiniNavbar(false);
    // }
    // if (data && data.protectedRoute && data.protectedRoute !== null) {
    //     // do nothing, let the user stay on the page
    // } else if (error || (data && data?.protectedRoute === null)) {
    //     console.log('errors', error);

    //     navigate('/404', { replace: true });
    // }

    useEffect(() => {
        dispatch({ type: SET_MENU, opened: false });
    }, [dispatch]);
    const isOnline = useOnlineStatus();

    return (
        <Box sx={{ display: 'flex' }}>
            {/* <Box
                style={{
                    position: 'fixed',
                    left: 0,
                    top: 0,
                    border: '1px solid red',

                    height: '34px',
                    width: '100%',
                    // background: "rgba(0,0,0,0.85)",
                    WebkitAppRegion: 'drag',
                    zIndex: 100000000
                }}
            /> */}
            <CssBaseline />
            {/* header */}
            <AppBar
                enableColorOnDark
                position="fixed"
                color="inherit"
                elevation={0}
                sx={{
                    boxShadow: '0px 8x 16px 0px rgba(0,0,0,1)',
                    zIndex: 10000,

                    top: 0,
                    left: 0,
                    height: '3.5rem',
                    // bgcolor: theme.palette.primary[200],
                    transition: leftDrawerOpened ? theme.transitions.create('width') : 'none'
                }}
                open={leftDrawerOpened}
            >
                <Toolbar
                    sx={{
                        bgcolor: theme.palette.grey[50],
                        // height: '3.5rem',

                        // bgcolor: route.pathname === '/' ? theme.palette.primary.main : theme.palette.background.paper,
                        // color: theme.palette.primary.main,
                        // bgcolor: isRouteHome ? theme.palette.primary.main : theme.palette.background.paper,
                        borderBottom: `1px solid ${theme.palette.primary.light}`,
                        borderTop: `1px solid ${theme.palette.primary.light}`,
                        transition: 'all 100ms ease 0s',
                        padding: '0 !important'
                    }}
                >
                    {/* {userContext && userContext?.user && ( */}
                    <Header handleLeftDrawerToggle={handleLeftDrawerToggle} />
                    {/* // )} */}
                </Toolbar>
            </AppBar>

            {/* drawer */}
            {/* {userContext && userContext?.user &&  */}
            <Sidebar drawerOpen={leftDrawerOpened} drawerToggle={handleLeftDrawerToggle} />
            {/* } */}

            {/* main content */}
            <Main theme={theme} open={leftDrawerOpened}>
                {!isOnline && (
                    <ErrorScreen
                        translucent
                        fixed
                        icon={<IconBroadcastOff color={theme.palette.error.main} stroke={2} size={72} />}
                        title="Sei offline"
                        subtitle="Per utilizzare quest'applicazione devi essere online. Controlla la tua connessione e riprova."
                        errbg={theme.palette.grey[50]}
                        loadingfg={theme.palette.primary[400]}
                        errfg={theme.palette.error.main}
                        loadingbg={theme.palette.grey[50]}
                        details={false}
                        err={{
                            message: 'test'
                        }}
                        // top="3rem"
                    />
                )}
                <Outlet context={[userContext, setUserContext]} />
            </Main>
        </Box>
    );
};
export default PublicLayout;
