/**
 * Typography used in theme
 * @param {JsonObject} theme theme customization object
 */

export default function themeTypography(theme) {
    return {
        // fontFamily: theme?.customization?.fontFamily,
        // !! GLOBAL
        fontFamily: `'Sequel Sans Medium Disp', sans-serif`,
        body: {
            fontFamily: `'Sequel Sans Roman Disp', sans-serif`,
            color: theme.primaryMain
        },
        h1: {
            fontFamily: `'Sequel Sans Medium Disp', sans-serif`,

            fontSize: '2.5rem',
            fontWeight: 700,
            color: theme.primaryMain
        },
        h2: {
            fontFamily: `'Sequel Sans Medium Disp', sans-serif`,

            fontSize: '2rem',
            fontWeight: 700
        },
        h6: {
            fontFamily: `'Sequel Sans Medium Disp', sans-serif`,

            fontSize: '0.875rem',
            fontWeight: 700
        },
        p: {
            fontFamily: `'Sequel Sans Roman Disp', sans-serif`,

            fontSize: '1rem',
            fontWeight: 400
        },
        title: {
            display: 'block',
            fontFamily: `'Sequel Sans Medium Disp', sans-serif`,
            width: '100%',
            fontSize: '2.75rem',
            lineHeight: '3rem',
            // paddingTop: '.5rem',
            color: theme.primaryDark
        },
        subtitle: {
            alignItems: 'center',
            display: 'flex',
            fontFamily: `'Sequel Sans Semi Bold Disp', sans-serif`,
            fontSize: '1.25rem',
            marginTop: '1rem',
            color: theme.primary200
        },
        summary: {
            display: 'block',
            fontFamily: `'Sequel Sans Medium Disp', sans-serif`,
            fontSize: '1rem',
            color: theme.primaryDark,
            whiteSpace: 'pre-line'
        },
        description: {
            display: 'block',
            paddingTop: '1rem !important',
            fontFamily: `'Sequel Sans Medium Disp', sans-serif`,
            fontSize: '1.25rem',
            color: theme.primaryDark,
            whiteSpace: 'pre-line'
        },
        link: {
            display: 'block',
            // fontFamily: `'Sequel Sans Semi Bold Obl Disp', sans-serif`,
            fontFamily: `'Sequel Sans Semi Bold Disp', sans-serif`,
            fontSize: '1.25rem',
            color: theme.primary200,
            textDecoration: 'none !important',
            marginTop: '1rem'
        },
        date: {
            display: 'block',
            fontFamily: `'Sequel Sans Semi Bold Disp', sans-serif`,
            fontSize: '1rem',
            width: '100%',
            textTransform: 'uppercase',
            color: theme.primary200
        }
    };
}
