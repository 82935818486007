export default function componentStyleOverrides(theme) {
    return {
        // Link: {
        //     styleOverrides: {
        //         root: {
        //             color: theme.textDark,
        //             textDecoration: 'none',
        //             '&:hover': {
        //                 textDecoration: 'none',
        //                 color: theme.textDark
        //             },
        //             '&:visited': {
        //                 color: theme.textDark
        //             }
        //         }
        //     }
        // },
        MuiPickersPopper: {
            styleOverrides: {
                root: {
                    boxShadow: 'none !important'
                }
            }
        },

        MuiTypography: {
            styleOverrides: {
                root: {
                    '& a': {
                        color: 'inherit',
                        textDecoration: 'underline',
                        '&:hover': {
                            textDecoration: 'none',
                            // color: theme.primary200,
                            opacity: 0.5
                        },
                        '&:visited': {
                            color: 'inherit'
                        }
                    },
                    '& strong': {
                        fontWeight: 600,
                        color: 'inherit'
                    }
                }
            }
        },
        MuiTabScrollButton: {
            styleOverrides: {
                root: {
                    // scroll button component
                    // '& .MuiTabs-indicator': {
                    background: theme.primaryMain,
                    opacity: 1,
                    color: theme.grey50
                    // }
                }
            }
        },

        MuiButton: {
            styleOverrides: {
                root: {
                    fontWeight: 400,
                    borderRadius: '0',
                    textTransform: 'none',
                    fontSize: '0.875rem'
                    // fontFamily: `'Inter', sans-serif`
                }
            }
        },

        MuiPaper: {
            defaultProps: {
                elevation: 0
            },
            styleOverrides: {
                root: {
                    backgroundImage: 'none'
                },
                rounded: {
                    borderRadius: `0`
                }
            }
        },
        MuiCardHeader: {
            styleOverrides: {
                root: {
                    color: theme.textDark,
                    padding: '24px'
                },
                title: {
                    fontSize: '1.125rem'
                }
            }
        },
        MuiCardContent: {
            styleOverrides: {
                root: {
                    padding: '24px'
                }
            }
        },
        MuiCardActions: {
            styleOverrides: {
                root: {
                    padding: '24px'
                }
            }
        },
        MuiListItemButton: {
            styleOverrides: {
                root: {
                    // fontFamily: `'Inter', sans-serif`,
                    // color: theme.darkTextPrimary,
                    // opacity: 0.35,
                    color: theme.darkTextPrimary,
                    borderLeft: `4px solid ${theme.grey300}`,
                    opacity: 0.4,

                    // // paddingBottom: '10px',
                    '&.Mui-selected': {
                        '.MuiListItemText-root .MuiTypography-root': {
                            fontWeight: 500
                        },
                        borderLeft: `4px solid ${theme.primaryMain}`,
                        opacity: 1,
                        color: theme.primaryMain,
                        background: 'none',
                        '&:hover': {
                            color: theme.grey800
                        }
                        // '& .MuiListItemIcon-root': {
                        //     color: theme.menuSelected
                        // }
                    },
                    '&:hover': {
                        opacity: 1,
                        backgroundColor: 'none',
                        background: 'none',
                        color: theme.grey800,
                        '& .MuiListItemIcon-root': {
                            color: theme.menuSelected
                        }
                    }
                }
            }
        },
        MuiListItem: {
            styleOverrides: {
                root: {
                    color: theme.darkTextPrimary,
                    // opacity: 0.4,
                    fontWeight: 400
                    // borderLeft: `4px solid ${theme.grey300}`,
                    // // paddingBottom: '10px',
                    // '&.Mui-selected': {
                    //     '.MuiListItemText-root .MuiTypography-root': {
                    //         fontWeight: 500
                    //     },
                    //     borderLeft: `4px solid ${theme.primaryMain}`,
                    //     opacity: 1,
                    //     color: theme.primaryMain,
                    //     background: 'none',
                    //     '&:hover': {
                    //         color: theme.grey800
                    //     }
                    //     // '& .MuiListItemIcon-root': {
                    //     //     color: theme.menuSelected
                    //     // }
                    // },
                    // '&:hover': {
                    //     opacity: 1,
                    //     // backgroundColor: 'none',
                    //     background: 'none',
                    //     color: theme.grey800
                    //     // '& .MuiListItemIcon-root': {
                    //     //     color: theme.menuSelected
                    //     // }
                    // }
                }
            }
        },
        MuiListItemText: {
            styleOverrides: {
                root: {
                    '>.MuiTypography-root': {
                        // fontFamily: `'Inter', sans-serif`,
                        fontWeight: 400
                    }
                }
            }
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    color: theme.darkTextPrimary,
                    minWidth: '36px'
                }
            }
        },
        MuiInput: {
            //      margin: 0 0 !important;
            // display: flex !important;
            // justify-content: stretch !important;
            // font-size: 1rem;
            // font-weight: 500 !important;
            // min-height: 2.5rem !important;
            // width: 100% !important;
            // padding: 0.25rem 2rem 0.25rem 0.5rem !important;
            // color: $grey600 !important;
            // // right gradient

            // // &.Mui-disabled {
            // //     color: $primaryMain !important;
            // //     // -webkit-text-fill-color: $primaryMain !important;
            // // }
            // & > .Mui-disabled {
            //     // color: $primaryMain !important;
            //     -webkit-text-fill-color: $grey500 !important;
            // }

            // &:active,
            // :focus {
            //     font-weight: 500 !important;

            //     color: $primaryMain !important;
            // }
            // & > ::placeholder {
            //     color: $grey200;
            //     font-size: 1rem;
            //     opacity: 1;
            //     font-weight: 500 !important;
            // }
            // > * {
            //     margin: 0 !important;
            // }
            styleOverrides: {
                root: {
                    margin: 0,
                    display: 'flex',
                    justifyContent: 'stretch',
                    fontSize: '1rem',
                    fontWeight: 500,
                    minHeight: '2.5rem',
                    width: '100%',
                    padding: '0.25rem 2rem 0.25rem 0.5rem',
                    color: `${theme.grey600} !important`,
                    '&.Mui-disabled': {
                        color: `${theme.grey500} !important`
                    },

                    '& > *': {
                        margin: 0
                    }
                }
            }
        },

        MuiSlider: {
            styleOverrides: {
                root: {
                    '&.Mui-disabled': {
                        color: theme.grey300
                    }
                },
                mark: {
                    backgroundColor: theme.paper,
                    width: '4px'
                },
                valueLabel: {
                    color: theme?.primaryLight
                }
            }
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    borderColor: theme.divider,
                    opacity: 1
                }
            }
        },
        MuiAvatar: {
            styleOverrides: {
                root: {
                    color: theme.primaryDark,
                    background: theme.primary200
                }
            }
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    '&.MuiChip-deletable .MuiChip-deleteIcon': {
                        color: 'inherit'
                    }
                }
            }
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    color: theme.paper,
                    background: theme.grey700
                },
                root: {
                    paddingLeft: 0
                }
            }
        }
    };
}
