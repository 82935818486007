import { useRoutes } from 'react-router-dom';

import { FrontendRoutes } from './RouteStructure';

import config from 'globalConfig';

export function PublicRoutes() {
    // const [userContext, setUserContext] = useState(UserContext);

    // useEffect(() => {
    //     setUserContext(UserContext, [RouteStructure]);
    // }, []);

    return useRoutes([FrontendRoutes], config.basename);
}
