const config = {
    // basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
    // adminBasename: 'admin',
    // url: 'https://tempio.agency',
    url: 'https://tempio.agency',
    appName: 'Tempio Agency',
    author: '@tunnelthree',
    appVersion: 'v1.12',
    locale: 'it_IT',
    basename: '',
    defaultPath: '/',
    productionBaseName: '',
    remoteDevServer: 'http://localhost:3000',
    // fontFamily: `'Times New Roman', sans-serif`,
    borderRadiusXs: '.25rem',
    borderRadiusSm: '.5rem',
    borderRadiusMd: '.75rem',
    borderRadiusLg: '1rem',
    borderRadiusXl: '2.5rem'
    // navbarHeight: 64,
};

export default config;
